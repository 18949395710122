import BasePageFrame from "../base-components/base-page-frame";
import WrapperBackoffice from "./wrapper-backoffice";
import { useIntl } from 'react-intl';
import { Box } from '@mui/material';

const FinancingOrders = () => {
    const intl = useIntl();
    return (
        <BasePageFrame
            noTitle={true}
            title="Financia tus compras"
            subtitle=""
            transparent
        >
            <Box
                sx={{
                    height: '100vh',
                    minHeight: '106vh',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <WrapperBackoffice type="buyer" />
            </Box>
        </BasePageFrame>
    );
};

export default FinancingOrders;