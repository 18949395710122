import { useRecoilValue } from 'recoil';
import userState from '../../atoms/user-state';
import { styled } from '@mui/material';
import { Role } from '../../types';

const WrapperBackoffice = ({ ...props }) => {
	const { type } = props;
	const user = useRecoilValue(userState);

	const StyledIFrame = styled('iframe')(({ theme }) => ({
		borderStyle: 'none',
		width: '100%',
		height: '100%',
		overflowY: 'hidden',
		backgroundColor: 'transparent',
	}));

	const objectToSend = {
		token: user.token,
		cuit: user.type_external === Role.CONSTRUCTOR && user.id_external ? null : user.cuit_external,
		id_external: user.id_external,
	}
	const encodedParam = btoa(JSON.stringify(objectToSend));

	return (
		<StyledIFrame
			title='frame'
			name='frame'
			src={`${process.env.REACT_APP_BACKOFFICE_BASE_URL}/shared/nuqlea-financing/${type}?token=${encodedParam}`}
		></StyledIFrame>
	);
};

export default WrapperBackoffice;
