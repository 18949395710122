import React, { Fragment, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
	Box,
	Button,
	Container,
	Divider,
	InputLabel,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Tooltip,
	Typography,
	styled,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import AgreementSelect from './agreement-select';
import { useContextBuyTokens } from './buy-tokens';
import { TooltipQuestionMarkIcon } from './confirmation-step';
import { AgreementProduct } from '../../../types';
import { formatNumberLocale, formatNumberWithCurrency, truncToDecimals } from '../../../utils/data-utils';
import { EastOutlined, WestOutlined } from '@mui/icons-material';
import { SET_QUANTITY, SET_EQUIVALENCE_PRODUCTS_MAP } from './reducer/actionNames';
import { useMobile } from '../../../hooks/use-mobile';

const ColumnHeader = styled(TableCell)(({ theme }) => ({
	color: '#000000',
	fontWeight: 'bold',
	verticalAlign: 'middle',
}));

const ColumnHeaderContainerCenterAlign = styled(Box)(({ theme }) => ({
	maxWidth: '100%',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	textAlign: 'left',
}));

const StyledCell = styled(TableCell)(({ theme }) => ({
	color: 'grey.600',
	fontWeight: 400,
	textAlign: 'center',
	verticalAlign: 'middle',
}));

const ReferencePriceSpan = styled('span')(({ theme }) => ({
	fontSize: '12px',
	fontWeight: 600,
}));

const QuantitySelectStep = () => {
	const intl = useIntl();
	const navigate = useNavigate();
	const isMobile = useMobile();
	const baseTheme = useTheme();
	const matchesXl = useMediaQuery(baseTheme.breakpoints.up('xl'));

	const [isQuantityInputOnFocus, setIsQuantityInputOnFocus] = useState(false);

	const [state, dispatch, fetch, totalsToRender] = useContextBuyTokens();
	const {
		agreement,
		benefit,
		collection,
		equivalenceProductsMap,
		isLoadingResponse,
		noAvailableTokensToBuy,
		quantity,
		quantityString,
		pricing,
		products,
		selectedAgreementId,
	} = state;

	const decimalsFromRedeemStep = useMemo(() => {
		if (!agreement?.redeem_step || Math.floor(agreement?.redeem_step) === agreement?.redeem_step) {
			return 0;
		} else {
			return agreement.redeem_step.toString().split('.')[1].length || 0;
		}
	}, [agreement]);

	const capitalizeFirstLetter = (s: string) => {
		if (typeof s !== 'string') return '';
		return s.charAt(0).toUpperCase() + s.slice(1);
	};

	const handleOnChangeEquivalenceInput = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		product: AgreementProduct
	) => {
		const value = truncToDecimals(parseFloat(e.target.value), decimalsFromRedeemStep);
		let newMap = new Map(equivalenceProductsMap);

		if (value > 0) {
			newMap = new Map(
				equivalenceProductsMap.set(product.id, {
					requireUnits: value,
					necessaryTokens: Number((value * product.equivalence).toFixed(agreement?.token_decimals ?? 2)),
				})
			);
		} else {
			newMap = new Map(equivalenceProductsMap.set(product.id, { requireUnits: null, necessaryTokens: null }));
		}

		let total = 0;
		newMap.forEach((value) => {
			total += value.necessaryTokens ?? 0;
		});
		const n = Number(total.toFixed(agreement?.token_decimals ?? 2));

		dispatch({
			type: SET_EQUIVALENCE_PRODUCTS_MAP,
			payload: { newMap, quantity: n, quantityString: formatNumberLocale(n) },
		});
	};

	return (
		<Box className="flex flex-col justify-start items-start">
			<AgreementSelect
				benefit={benefit}
				dispatch={dispatch}
				isLoading={isLoadingResponse}
				noAvailableTokensToBuy={noAvailableTokensToBuy}
				selectedAgreement={agreement}
				selectedAgreementId={selectedAgreementId}
				timeValidity={totalsToRender.timeValidity}
			/>
			{!isLoadingResponse && agreement && (
				<>
					<Divider sx={{ width: '100%', my: '35px', borderColor: '#E3E3E3' }} />
					<Typography>
						{intl.formatMessage({ id: 'buy_tokens.quantity_select.title' })}
						{!isMobile && (
							<Tooltip
								title={
									<Typography sx={{ color: '#FFFFFF' }}>
										{intl.formatMessage(
											{ id: 'buy_tokens.quantity_select.tooltip_title' },
											{ unitName: agreement.unity }
										)}
									</Typography>
								}
							>
								<TooltipQuestionMarkIcon sx={{ width: '10px' }} />
							</Tooltip>
						)}
					</Typography>
					<Table>
						<TableHead sx={{ color: '#000000', fontWeight: 'bold' }}>
							<TableRow>
								<ColumnHeader
									colSpan={1}
									sx={{
										width: { xs: '80%', md: '36%' },
									}}
								>
									{intl.formatMessage({ id: 'buy_tokens.quantity_select.first_column_title' })}
								</ColumnHeader>
								<ColumnHeader
									colSpan={1}
									sx={{
										width: { xs: 'unset', sm: '16%' },
									}}
								>
									<ColumnHeaderContainerCenterAlign>
										{agreement.label_units_in_presentation}
									</ColumnHeaderContainerCenterAlign>
								</ColumnHeader>
								<ColumnHeader
									colSpan={1}
									sx={{
										width: { xs: 'unset', sm: '16%' },
									}}
								>
									<ColumnHeaderContainerCenterAlign>
										{intl.formatMessage({ id: 'buy_tokens.quantity_select.third_column_title' })}
										{!isMobile && (
											<Tooltip
												title={
													<Typography sx={{ color: '#FFFFFF' }}>
														{intl.formatMessage(
															{ id: 'buy_tokens.quantity_select.tooltip_third_column_title' },
															{ unitName: agreement.unity, indicativeLetter: agreement.unity === 'pallet' ? '' : 'a' }
														)}
													</Typography>
												}
											>
												<TooltipQuestionMarkIcon sx={{ width: '10px', marginLeft: '7px' }} />
											</Tooltip>
										)}
									</ColumnHeaderContainerCenterAlign>
								</ColumnHeader>
								{!isMobile && (
									<>
										<ColumnHeader colSpan={1} sx={{ width: '16%' }}>
											<ColumnHeaderContainerCenterAlign>
												{intl
													.formatMessage(
														{ id: 'buy_tokens.quantity_select.fourth_column_title' },
														{
															unitName: capitalizeFirstLetter(agreement.unity ?? ''),
															indicativeLetter: agreement.unity === 'pallet' ? 'o' : 'a',
														}
													)
													.split('\n')
													.map((line: string, index: number) => {
														if (
															index + 1 <
															intl.formatMessage({ id: 'buy_tokens.quantity_select.fourth_column_title' }).split('\n')
																.length
														) {
															return (
																<Fragment key={index + 'buy_tokens.quantity_select.fourth_column_title'}>
																	{line}
																	<br />
																</Fragment>
															);
														} else {
															return (
																<Fragment key={index + 'buy_tokens.quantity_select.fourth_column_title'}>
																	{line}
																</Fragment>
															);
														}
													})}
											</ColumnHeaderContainerCenterAlign>
										</ColumnHeader>
										<ColumnHeader colSpan={1} sx={{ width: '16%' }}>
											<ColumnHeaderContainerCenterAlign>
												{intl
													.formatMessage({ id: 'buy_tokens.quantity_select.fith_column_title' })
													.split('\n')
													.map((line, index) => {
														if (
															index + 1 <
															intl.formatMessage({ id: 'buy_tokens.quantity_select.fith_column_title' }).split('\n')
																.length
														) {
															return (
																<Fragment key={index + 'buy_tokens.quantity_select.fith_column_title'}>
																	{line}
																	<br />
																</Fragment>
															);
														} else {
															return (
																<Fragment key={index + 'buy_tokens.quantity_select.fith_column_title'}>{line}</Fragment>
															);
														}
													})}
											</ColumnHeaderContainerCenterAlign>
										</ColumnHeader>
									</>
								)}
							</TableRow>
						</TableHead>
						<TableBody>
							{products.map((product) => (
								<TableRow key={product.id}>
									<StyledCell sx={{ textAlign: 'left' }}>
										{product.name}
										<br />
										<ReferencePriceSpan>
											{intl.formatMessage(
												{ id: 'buy_tokens.quantity_select.reference_price' },
												{
													price: formatNumberWithCurrency((pricing?.price ?? 0) * product.equivalence, 2),
													unitName: agreement.unity,
												}
											)}
										</ReferencePriceSpan>
									</StyledCell>
									<StyledCell>
										{
											product.units_in_presentation ?
											`${formatNumberLocale(product.units_in_presentation)} ${product.unit_type ? ` ${product.unit_type}` : ''}` :
											'-'
										}
									</StyledCell>
									<StyledCell>{formatNumberLocale(product.equivalence)}</StyledCell>
									{!isMobile && (
										<>
											<StyledCell>
												<Box
													sx={{
														width: '100%',
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
													}}
												>
													<TextField
														sx={{
															width: '45%',
															'& .MuiInputBase-input': {
																height: '29px',
																width: '85%',
																marginLeft: '7%',
																boxSizing: 'border-box',
																textAlign: 'center',
																px: '0px',
															},
														}}
														type="number"
														inputProps={{
															min: 0,
															step: agreement.redeem_step || 1,
														}}
														value={equivalenceProductsMap.get(product.id)?.requireUnits || ''}
														onChange={(event) => handleOnChangeEquivalenceInput(event, product)}
													/>
												</Box>
											</StyledCell>
											<StyledCell>
												{Boolean(equivalenceProductsMap.get(product.id)?.requireUnits)
													? formatNumberLocale(equivalenceProductsMap.get(product.id)?.necessaryTokens ?? 0)
													: '-'}
											</StyledCell>
										</>
									)}
								</TableRow>
							))}
						</TableBody>
					</Table>
					<Box
						sx={{
							position: 'fixed',
							width: { xs: '100vw', lg: `calc(100vw - ${matchesXl ? '280px' : '250px'})` }, // 10px extra from scrollbar
							left: { xs: '0', lg: '240px', xl: '270px' }, // from navbar width
							bottom: 0,
							backgroundColor: '#EAEFFF',
							display: 'flex',
							justifyContent: 'space-around',
							alignItems: 'center',
							height: { xs: '193', sm: '117px' },
						}}
					>
						<Container
							maxWidth="lg"
							sx={{
								display: 'flex',
								flexDirection: { xs: 'column', sm: 'row' },
								justifyContent: { xs: 'center', sm: 'flex-end' },
								alignItems: 'center',
								width: '100%',
								marginRight: '95px',
							}}
						>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'start',
									alignItems: 'start',
								}}
							>
								<InputLabel
									sx={{ fontFamily: 'inherit', fontWeight: 500, color: '#4B4B4B', marginLeft: '3px' }}
									variant="standard"
								>
									{intl.formatMessage({ id: 'buy_tokens.quantity_select.necessary_tokens' })}
								</InputLabel>
								<TextField
									value={isQuantityInputOnFocus ? quantityString : formatNumberLocale(quantity)}
									variant="outlined"
									sx={{
										width: '250px',
										height: { xs: '35px', sm: '50px' },
										'& .MuiOutlinedInput-input': {
											fontWeight: 700,
											textFillColor: quantity > (collection?.stock ?? 0) ? '#FF551F !important' : '#4B4B4B !important',
											border: '1px solid #D9D9D9',
											backgroundColor: '#FFFFFF',
											padding: { xs: '5.5px 14px', sm: '16.5px 14px' },
										},
									}}
									onFocus={() => {
										setIsQuantityInputOnFocus(true);
										const str = quantityString.replace(/\./g, '');
										dispatch({ type: SET_QUANTITY, payload: { str, n: quantity } });
									}}
									onBlur={() => {
										setIsQuantityInputOnFocus(false);
										dispatch({ type: SET_QUANTITY, payload: { str: formatNumberLocale(quantity), n: quantity } });
									}}
									onChange={(e) => {
										const decimals = agreement.token_decimals || 2;
										const regex = new RegExp(`^\\d*[,.]?\\d{0,${decimals}}$`);
										// can declare the decimals with dot or comma
										if (e.target.value === '' || regex.test(e.target.value)) {
											const n = Number(e.target.value.replace(',', '.'));
											dispatch({ type: SET_QUANTITY, payload: { str: e.target.value, n } });
										}
									}}
								/>
								<Typography
									sx={{
										color: '#4B4B4B',
										fontSize: '12px',
										marginLeft: '3px',
										marginTop: '5px',
									}}
									variant="body1"
								>
									{intl.formatMessage(
										{ id: 'buy_tokens.quantity_select.min_and_max_tokens' },
										{
											min: formatNumberLocale(pricing?.min_purchase ?? 0),
											max: formatNumberLocale(truncToDecimals(pricing?.max_purchase ?? 0, decimalsFromRedeemStep)),
										}
									)}
								</Typography>
							</Box>
							{!isMobile && (
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										alignItems: 'center',
										width: '18px',
										height: '30px',
										marginLeft: '15px',
									}}
								>
									<EastOutlined sx={{ color: '#000000' }} />
									<WestOutlined sx={{ color: '#000000', marginLeft: '-2px', marginTop: '-3px' }} />
								</Box>
							)}
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'start',
									alignItems: 'start',
									marginLeft: { xs: 'unset', sm: '15px' },
								}}
							>
								<InputLabel
									sx={{ fontFamily: 'inherit', fontWeight: 500, color: '#4B4B4B', marginLeft: '3px' }}
									variant="standard"
								>
									{intl.formatMessage({ id: 'buy_tokens.quantity_select.currency' })}
								</InputLabel>
								<TextField
									value={formatNumberWithCurrency((pricing?.price ?? 0) * quantity, 2)}
									variant="outlined"
									disabled
									sx={{
										width: '250px',
										height: { xs: '35px', sm: '50px' },
										'& .Mui-disabled': {
											fontWeight: 700,
											textFillColor: '#4B4B4B !important',
											backgroundColor: '#FFFFFF',
											border: '1px solid #D9D9D9',
										},
										'& .MuiOutlinedInput-input': {
											padding: { xs: '5.5px 14px', sm: '16.5px 14px' },
										},
									}}
								/>
								<Typography
									sx={{
										color: '#4B4B4B',
										fontSize: '12px',
										marginLeft: '3px',
										marginTop: '5px',
									}}
									variant="body1"
								>
									{intl.formatMessage({ id: 'buy_tokens.quantity_select.clarification_price' })}
								</Typography>
							</Box>
							<Button
								variant="contained"
								sx={{
									backgroundColor: '#406AFF',
									color: '#FFFFFF',
									borderRadius: '5px',
									'&:hover': { backgroundColor: '#406AFF' },
									marginLeft: '35px',
									alignSelf: { xs: 'end', sm: 'inherit' },
								}}
								onClick={() => navigate(`/my-tokens/buy/confirmation-step?agreement=${selectedAgreementId}`)}
								disabled={quantity < (pricing?.min_purchase ?? 0) || quantity > (pricing?.max_purchase ?? 0)}
							>
								{intl.formatMessage({ id: 'common.continue' })}
							</Button>
						</Container>
					</Box>
				</>
			)}
		</Box>
	);
};

export default QuantitySelectStep;
