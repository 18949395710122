import axios, { AxiosResponse } from 'axios';
import { User } from '../types';

const customAxios = (user?: User) => {
    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
    });

    axiosInstance.interceptors.request.use(
        (config) => {
            config.headers['Accept'] = 'application/json';
            if (user) {
                config.headers['Authorization'] = `Bearer ${user.token}`;
            }

            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    return axiosInstance;
};

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

export interface createInvoiceRequest {
    orderId: string;
    invoice: any;
    user: User;
}

interface SubmitQuotationResponse {
    id_lista: string;
}

const client = {
    createInvoice: async (params: createInvoiceRequest) => {

        const { orderId, invoice, user } = params;
        const client = customAxios(user);

        try {
            const response = await client.post<any>('/invoices',
                {
                    orderId: orderId,
                    invoice: invoice
                }
            );

            return responseBody(response);
        } catch (error) {
            console.error('Ocurrió un error: ', error);
            throw error;
        }
    }
};

export default client;