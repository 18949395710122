import BasePageFrame from "../base-components/base-page-frame";
import WrapperBackoffice from "./wrapper-backoffice";
import { useIntl } from 'react-intl';
import { Box } from "@mui/material";

const Financing = () => {
    const intl = useIntl();
    return (
        <BasePageFrame
            title='Financia tus ventas'
            subtitle=''
            transparent
        >
            <Box 
               sx={{
                   height: '100vh',
                   minHeight: '106vh',
                   display: 'flex',
                   flexDirection: 'column'
               }}
            >
                <WrapperBackoffice type="seller" />
            </Box>
        </BasePageFrame>
    );
};

export default Financing;