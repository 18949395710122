import { ListDetails, QuoteStatus, QuoteStatusForSeller, Role, SellerListDetails } from '../../types';

const isStatusAnyOf = (values: string[], details?: ListDetails | SellerListDetails): boolean => {
	const status = details?.quotation?.status;
	if (!status || status === 'No concretada') {
		return false;
	}

	return values.includes(status);
};

export const constructorStatuses = [
	{
		id: QuoteStatus.EN_PROCESO_DE_COTIZACION,
		isActive: (details: ListDetails) => isStatusAnyOf([QuoteStatus.EN_PROCESO_DE_COTIZACION], details),
		isCompleted: (details: ListDetails) =>
			isStatusAnyOf(
				[
					QuoteStatus.EN_PROCESO_DE_ENTREGA,
					'Pendiente de entrega',
					'Entrega parcial',
					'No concretada',
					QuoteStatus.COTIZACION_RECIBIDA,
					QuoteStatus.EN_PROCESO_DE_COMPRA,
					QuoteStatus.EN_PROCESO_DE_ENTREGA,
					QuoteStatus.COMPRA_FINALIZADA,
				],
				details
			),
	},
	{
		id: QuoteStatus.COTIZACION_RECIBIDA,
		getWarning: (details: ListDetails) => {
			const status = details.quotation.status;
			const metadata = details.metadata;

			if (
				metadata.has_proposalV2_related &&
				metadata.proposalV2_status === 'awaiting_response' &&
				metadata.proposalV2_due_date &&
				new Date(metadata.proposalV2_due_date) < new Date()
			) {
				return 'VENCIDA';
			}

			if (
				status === QuoteStatus.COTIZACION_RECIBIDA &&
				metadata.is_outdated &&
				!metadata.accepted &&
				!metadata.has_proposalV2_related
			) {
				return 'VENCIDA';
			}

			return undefined;
		},
		isActive: (details: ListDetails) => isStatusAnyOf([QuoteStatus.COTIZACION_RECIBIDA], details),
		isCompleted: (details: ListDetails) =>
			isStatusAnyOf(
				[
					QuoteStatus.EN_PROCESO_DE_ENTREGA,
					'Pendiente de entrega',
					'Entrega parcial',
					'No concretada',
					QuoteStatus.EN_PROCESO_DE_COMPRA,
					QuoteStatus.EN_PROCESO_DE_ENTREGA,
					QuoteStatus.COMPRA_FINALIZADA,
				],
				details
			),
	},
	{
		id: QuoteStatus.EN_PROCESO_DE_COMPRA,
		isActive: (details: ListDetails) => isStatusAnyOf([QuoteStatus.EN_PROCESO_DE_COMPRA], details),
		isCompleted: (details: ListDetails) =>
			isStatusAnyOf(
				[
					QuoteStatus.EN_PROCESO_DE_ENTREGA,
					'Pendiente de entrega',
					'Entrega parcial',
					'No concretada',
					QuoteStatus.EN_PROCESO_DE_ENTREGA,
					QuoteStatus.COMPRA_FINALIZADA,
				],
				details
			),
	},
	{
		id: QuoteStatus.EN_PROCESO_DE_ENTREGA,
		isActive: (details: ListDetails) =>
			isStatusAnyOf([QuoteStatus.EN_PROCESO_DE_ENTREGA, 'Pendiente de entrega'], details),
		isCompleted: (details: ListDetails) =>
			isStatusAnyOf(['Entrega parcial', 'No concretada', QuoteStatus.COMPRA_FINALIZADA], details),
	},
	{
		id: QuoteStatus.COMPRA_FINALIZADA,
		isActive: (details: ListDetails) => details.quotation.status === QuoteStatus.COMPRA_FINALIZADA,
		isCompleted: (details: ListDetails) => isStatusAnyOf(['No concretada'], details),
	},
];
export const sellerStatuses = [
	{
		id: 'Pendiente de cotización',
		getWarning: (details: SellerListDetails) => {
			const status = details.quotation.status;
			const { requote_request, requote_cause_id } = details.metadata;

			if (
				![QuoteStatusForSeller.PENDING_QUOTATION, 'Pendiente de cotización'].includes(status) ||
				!requote_request ||
				!requote_cause_id
			) {
				return undefined;
			}

			if (requote_cause_id === '1') {
				return 'EXTENDER VIGENCIA';
			}

			return 'RECOTIZACIÓN';
		},
		isActive: (details: SellerListDetails) =>
			isStatusAnyOf([QuoteStatusForSeller.PENDING_QUOTATION, 'Pendiente de cotización'], details),
		isCompleted: (details: SellerListDetails) =>
			isStatusAnyOf(
				[
					'En proceso de entrega',
					'Entrega parcial',
					'No concretada',
					QuoteStatusForSeller.AWAITING_FOR_RESPONSE,
					QuoteStatusForSeller.IN_PURCHASE_PROCESS,
					'Pendiente de Entrega',
					QuoteStatusForSeller.FINISHED_PURCHASE,
				],
				details
			),
	},
	{
		id: QuoteStatusForSeller.AWAITING_FOR_RESPONSE,
		isActive: (details: SellerListDetails) => isStatusAnyOf([QuoteStatusForSeller.AWAITING_FOR_RESPONSE], details),
		isCompleted: (details: SellerListDetails) =>
			isStatusAnyOf(
				[
					'En proceso de entrega',
					'Entrega parcial',
					'No concretada',
					QuoteStatusForSeller.IN_PURCHASE_PROCESS,
					'Pendiente de Entrega',
					QuoteStatusForSeller.FINISHED_PURCHASE,
				],
				details
			),
	},

	{
		id: QuoteStatusForSeller.IN_PURCHASE_PROCESS,
		isActive: (details: SellerListDetails) => isStatusAnyOf([QuoteStatusForSeller.IN_PURCHASE_PROCESS], details),
		isCompleted: (details: SellerListDetails) =>
			isStatusAnyOf(
				[
					'En proceso de entrega',
					'Entrega parcial',
					'No concretada',
					'Pendiente de Entrega',
					QuoteStatusForSeller.FINISHED_PURCHASE,
				],
				details
			),
	},
	{
		id: 'Pendiente de Entrega',
		isActive: (details: SellerListDetails) =>
			isStatusAnyOf(['Pendiente de Entrega', QuoteStatusForSeller.IN_DELIVERY_PROCESS], details),
		isCompleted: (details: SellerListDetails) =>
			isStatusAnyOf(['Entrega parcial', 'No concretada', QuoteStatusForSeller.FINISHED_PURCHASE], details),
	},
	{
		id: QuoteStatusForSeller.FINISHED_PURCHASE,
		isActive: (details: SellerListDetails) => details.quotation.status === QuoteStatusForSeller.FINISHED_PURCHASE,
		isCompleted: (details: SellerListDetails) => isStatusAnyOf(['No concretada'], details),
	},
];
