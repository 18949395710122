import { ReactNode } from 'react';

export enum Role {
	SELLER = 'seller',
	CONSTRUCTOR = 'constructor',
}

export enum ListStatus {
	AWAITING_FOR_RESPONSE = 'ESPERANDO RESPUESTA',
}

export enum BudgetStatus {
	IN_QUOTATION = 'En cotización',
	WON_QUOTATION = 'Ganada',
	RECEIVED_QUOTATION = 'Cotización recibida',
	LOST_QUOTATION = 'Perdida',
	DISMISSED_QUOTATION = 'Desestimada',
}

export enum QuoteStatus {
	PRIMERA_CARGA = 'PRIMERA CARGA',
	PEDIDOS_DE_COTIZACION_ENVIADOS = 'Pedidos de cotización enviados',
	EN_PROCESO_DE_COTIZACION = 'En proceso de cotización',
	COTIZACION_RECIBIDA = 'Cotización recibida',
	EN_PROCESO_DE_COMPRA = 'En proceso de compra',
	EN_PROCESO_DE_ENTREGA = 'En proceso de entrega',
	COMPRA_FINALIZADA = 'Compra finalizada',
	NO_CONCRETADA = 'No concretada',
	SIN_ESTADO = 'Sin estado',
}

export enum QuoteStatusForSeller {
	PENDING_QUOTATION = 'A cotizar/Recotizar',
	AWAITING_FOR_RESPONSE = 'Aguardando respuesta',
	IN_PURCHASE_PROCESS = 'En proceso de venta',
	IN_DELIVERY_PROCESS = 'En proceso de entrega',
	FINISHED_PURCHASE = 'Venta finalizada',
	NOT_COMPLETED_SALE = 'No concretada',
}

export enum TokenDetailStatus {
	REQUEST_RECEIVED = 'Solicitud recibida',
	INVOICE_SENT = 'Emisión de factura',
	PAID = 'Pago',
	ACCREDITED = '¡Acreditado!',
	REQUEST_SENT = 'Solicitud enviada',
	INVOICE_AWAIT = 'Aguardando factura',
	PAYMENT_SENT = 'Aguardando pago',
	FINISHED = 'Finalizado',
}

export enum RedeemRequestTypes {
	SHIPPING = 'shipping',
	WITHDRAWAL = 'withdrawal',
}

export const RedeemTypesMapper = {
	[RedeemRequestTypes.SHIPPING]: 'Envío a obra',
	[RedeemRequestTypes.WITHDRAWAL]: 'Retiro a cargo del comprador',
};

export enum RedeemRequestStatus {
	QUOTE_SENT = 'Presupuesto enviado',
	AWAITING = 'Aguardando respuesta',
	REQUEST_SENT = 'Solicitud enviada',
	QUOTE_RECEIVED = 'Presupuesto recibido',
	REQUEST_RECEIVED = 'Solicitud recibida',
	INVOICE = 'Emisión de factura',
	REDEEM_APPROVED = 'Canje aprobado',
	REQUEST_APPROVED = 'Solicitud aprobada',
	PENDING_RELEASE = 'Entregas pendientes',
	AWAITING_PAYMENT = 'Aguardando pago',
	PAYMENT = 'Pago',
	DELIVERED = 'Entregado',
	SHIPPING = 'Envío',
}

export interface RedeemRequestData {
	redeem_request: any;
}

export interface List {
	whatsapp_list_id: string;
	list_id: string;
	list_migration_id: string;
	order_id: string;
	order_migration_id: string;
	order_status: string;
	list_status: string;
	list_status_for_constructor: string;
	delivery_status_label: string;
	delivery_mode_label: string;
	comparative_version_number: string;
	seller_label: string;
	quotation_order_date: string;
	reception_date: string;
	product_reference: string;
	project_name: string;
	comparative_expiration_date: string;
	list_env: string;
	financing_required: string;
	reference_amount: string;
	comparative_quotation_sent_date: string;
	quotation_seller_name: string;
	next_delivery_date: string;
	order_delivery_date: string;
	invoice_date: string;
	order_closed_date: string;
	days_awaiting_for_payment_receipt: number;
	list_status_for_seller: string;
	locality_label: string;
	address_label?: string;
	address_city?: string;
	address_province?: string;
	client_name: string;
	created_by_name?: string;
	show_constructor_in_template: string;
	quoation_type: string;
	days_waiting_for_quotation: string;
	final_amount: string;
	quotation_amount: string;
	quote_status: string;
	quotation_id: string;
	shared_comparative: boolean;
	proposal_id: string;
	proposal_due_date: string;
}

export interface ListCount {
	list_status_for_constructor: string;
	list_status_for_seller: string;
	status_count: number;
}

export interface Lists {
	lists: List[];
	count_lists?: ListCount[];
}

export enum Theme {
	DARK = 'dark',
	LIGHT = 'light',
}

export interface Response<T> {
	data?: T;
	error?: boolean;
}

export interface FilterValues {
	[id: string]: any;
}

export abstract class FilterField<T> {
	id: string;
	label: string;
	placeholder?: string;
	value?: T;

	constructor(id: string, label: string, placeholder?: string, value?: T) {
		this.id = id;
		this.label = label;
		this.placeholder = placeholder;
		this.value = value;
	}
}

export class DateFilterField extends FilterField<Date> {
	constructor(id: string, label: string, placeholder?: string, value?: Date) {
		super(id, label, placeholder, value);
	}
}

export class TextFilterField extends FilterField<string> {
	constructor(id: string, label: string, placeholder?: string, value?: string) {
		super(id, label, placeholder, value);
	}
}

export interface Option {
	id: string;
	label: string;
}

export class SelectFilterField extends FilterField<string> {
	options: Option[];

	constructor(id: string, label: string, placeholder?: string, options?: Option[], value?: string) {
		super(id, label, placeholder, value);
		this.options = options || [];
	}
}

export interface ListDetails {
	quotation: Quotation;
	files: FilesDetails;
	last_event: any;
	metadata: Metadata;
	delivery: Delivery[];
	cancelation_reasons: CancelationReason[];
	events?: Events;
	unread_messages: number;
	event_state?: EventStates;
}

export interface Quotation {
	status: string;
	delivery_status?: string;
	quotation_request_date: string;
	quotation_request_file?: string;
	products: string;
	created_by_name?: string;
	project: string;
	seller?: {
		name: string;
		image: string;
		cuit: number;
	};
	documentation_status?: string;
	address?: string;
	locality?: string;
}

export interface FilesDetails {
	nuqlea_estimate: {
		date_loaded: string;
		date_due: string;
		id: string;
		comparative_version_number?: number | null;
		approved_by: string | null;
		approved_at: string | null;
		confirmable: boolean;
		winner_quotations?: string[];
		gcs_file_path?: string;
	};
	purchase_order: {
		date_loaded: string | null;
		id: string | null;
		gcs_file_path?: string;
		can_upload: boolean;
	};
	invoice: {
		date_loaded: string;
		id: string;
		gcs_file_path?: string;
	};
	payment: {
		date_loaded: null;
		id: null;
		can_upload: true;
		gcs_file_path?: string;
	};
}

export interface Document {
	file_id: string;
	id_doc?: string;
	list_id?: string | null;
	order_id?: string;
	comment?: string | null;
	file_type_id?: number;
	file_path?: string;
	drive_file_id?: string;
	label_file_type?: string;
	parent_documents_ids?: string | null;
	timestamp: string;
	constructor_id: string;
	created_by?: string;
	created_at?: string;
	created_by_name?: string;
	unity?: string;
	children: Document[] | [];
	gcs_file_path?: string;
}

export interface FilesList {
	data: Document[];
	map: (file: any) => Document[];
}

export interface CommentsList {
	comments: Comment[];
	map: (comment: any) => Comment[];
}

export interface Comment {
	id: string;
	message: string;
	message_type: string;
	origin: string;
	list_id: string;
	order_id: string | null;
	read_at: string | null;
	file_id: string | null;
	type: string;
	created_by: string | null;
	created_at: string;
}

export interface DeliveriesList {
	data: Delivery[];
	map: (delivery: any) => Delivery[];
}

export interface Remito {
	delivery_id: string | null;
	document_id: string | null;
	document_file_id: string | null;
	document_date: string | null;
	document_gcs_path: string | null;
}

export interface Delivery {
	id: string;
	order_id?: string;
	locality_id: any;
	type: number;
	tipo_entrega_id?: number;
	type_label: string;
	estimated_delivery_date: string;
	delivery_date: string | null;
	comment: string;
	// document_file_id: string;
	redeem_request_id?: string | null;
	document_date: string | null;
	locality: any;
	city: any;
	province: any;
	completeness: string | null;
	express_name: string | null;
	tracking_code: string | null;
	status: number;
	delivery_action_type: string | null;
	children: Remito[] | [];
	products?: any[];
	files?: { file?: File; id_doc: string; updated: boolean; id?: string }[];
}

export interface Metadata {
	accepted: boolean;
	pendings: any;
	productos: any;
	project: string;
	quote: any;
	requote_request?: any;
	list_id: string;
	whatsapp_list_id?: string;
	order_id: string;
	order_migration_id: number;
	quotation_id: string;
	status: string;
	order_status: string;
	environment: string;
	is_outdated: boolean;
	due_date: string;
	has_actions: boolean;
	has_files: boolean;
	has_proposalV2_related: boolean;
	proposalV2_due_date: string | null;
	proposalV2_created_date: string | null;
	proposalV2_status: string | null;
	files_folder_id: string;
	can_upload_oc: boolean;
	can_upload_cp: boolean;
	requote_cause_id?: string;
	seller_files?: object[];
}

export interface CancelationReason {
	cancellation_id: number;
	cancellation_type: string;
}

export interface FileType {
	id: number;
	name: string;
}

export interface Events {
	user_action_type_label: string;
	result_id: number;
}

export enum Action {
	CONFIRM = 'confirm',
	REJECT = 'reject',
	RE_QUOTE = 'recotize',
	FP = 'final_proposal',
}

export interface UserResponse {
	error: boolean;
	user: User;
}

export interface User {
	id_constructora: string;
	type_external: Role;
	id_external: string;
	admin: boolean;
	area?: string;
	role: CollaboratorRole;
	status: CollaboratorStatus;
	accepted_terms: boolean;
	allowed_routes?: any;
	id: number;
	appsheet_user_id: number;
	name: string;
	email: string;
	phone: string;
	image: string;
	profile_image?: string;
	cuit_external: number;
	enviroment: string;
	owner_nuqlea: {
		id: number;
		name: string;
		email: string;
		image: string;
		role: string;
	};
	version: string;
	token: string;
	name_external: string;
	knock_token: string;
	feature_names?: any;
}

export interface SellerListDetails {
	quotation: SellerQuotation;
	files?: SellerFilesDetails;
	metadata: SellerMetadata;
	delivery?: Delivery[];
}

export interface SellerQuotation {
	status: string;
	lost_cause?: string;
	quotation_request_date: string;
	products: string;
	locality?: string;
	buyer: string;
	constructor_id: string;
	constructor_cuit: number;
	constructor_business_name?: string;
	order_status: string;
	order_id?: string;
	order_migration_id?: number;
	can_upload_order_documents: boolean;
	address?: string;
	business_id?: string;
	business_social?: string;
	business_cuit?: number;
}

export interface SellerFilesDetails {
	quotation_request_file: {
		date_loaded: string;
		date_due: string;
		id: string;
		show_pedido: boolean;
		gcs_file_path?: string;
	};
	last_sent_quotation: {
		date_loaded: string;
		date_due: string;
		id: string;
		gcs_file_path?: string;
		show_last_quotation: boolean;
		can_upload: boolean;
		offered_coditions: {
			availability?: boolean;
			quote_comment?: string;
			payment?: string;
			shipping_request?: string;
		};
	};
	construction_plan?: {
		show_plan: boolean;
		date_loaded: string;
		id: string;
		gcs_file_path?: string;
	};
}

export interface SellerMetadata {
	accepted?: boolean;
	pendings?: boolean;
	requote_request: boolean;
	requote_cause_id: string;
	requote_cause_label: string;
	list_id: string;
	max_version_quotation: number;
	quotation_comment?: string;
	list_migration_id: string;
	files_folder_id?: string;
	status: string;
	env_list: string;
	is_outdated: boolean;
	has_files: boolean;
	can_upload_order_documents?: boolean;
	order_id?: string;
	order_migration_id?: number;
	whatsapp_list_id?: string;
}

export enum Availability {
	TOTAL = 'Stock total',
	PARTIAL = 'Stock parcial',
	MANUFACTURNING_REQUIRED = 'Requiere fabricación',
	OTHER = 'Otro',
}

export enum DeliveryCompleteness {
	COMPLETE = 'completa',
	PARTIAL = 'parcial',
}

export enum Payment {
	BEFORE_DELIVERY = 'Pago anticipado',
	IN_0_DAYS = 'echeq 0 dias',
	IN_15_DAYS = 'echeq 15 dias',
	IN_30_DAYS = 'echeq 30 dias',
	IN_45_DAYS = 'echeq 45 dias',
	IN_60_DAYS = 'echeq 60 dias',
	IN_90_DAYS = 'echeq 90 dias',
	OTHER = 'otro',
}

export enum DeliveryType {
	DELIVERED = 'entregado',
	TAKEAWAY = 'retirado',
	EXPRESO = 'expreso',
	REMITO = 'remito',
}

export enum EventStates {
	WAITING_FOR_USER_ACTION = 'waiting_for_user_action',
	WAITING_FOR_ACCEPTANCE = 'waiting_for_acceptance',
	OUTDATED_COMPARATIVE = 'outdated_comparative',
	WAITING_FOR_REQUOTE = 'waiting_for_requote',
	EXTENDED_TIME_QUOTATION = 'extended_time_quotation',
	ACCEPTED_REQUOTE = 'accepted_requote',
	ACCEPTED = 'accepted',
	BEING_REVIEWED = 'being_reviewed',
	REUPLOAD_OC = 'reupload_oc',
	WAITING_FOR_COMPARISON = 'waiting_for_comparison',
	WAITING_FOR_OC = 'waiting_for_oc',
	WAITING_FOR_OC_CONFIRMATION = 'waiting_for_oc_confirmation',
	ACCEPTED_OC = 'accepted_oc',
	REJECTED_OC = 'rejected_oc',
	REQUOTE_BY_OP = 'requote_by_op',
	WAITING_FOR_REVISION_NEW_OC = 'waiting_for_revision_new_oc',
	NEEDS_USER_REVISION = 'needs_user_revision',
	WAITING_FOR_REJECTION = 'waiting_for_rejection',
	ACCEPTED_REJECTION = 'accepted_rejection',
	NO_ACTION_POSSIBLE = 'no_action_possible',
	UNISELLER_NOT_CONFIRMED = 'uniseller_not_confirmed',
	MULTISELLER_NOT_CONFIRMED = 'multiseller_not_confirmed',
	MULTISELLER_CONFIRMED = 'multiseller_confirmed',
}

export enum Trilean {
	TRUE = '0',
	FALSE = '1',
	UNKNOWN = '2',
}

export interface Project {
	direccion: string | null;
	id_constructora: string;
	id_proyecto: string;
	nombre_proyecto: string;
}

export interface Projects {
	results: Project[];
}

export interface FileWithContent {
	base64: string | undefined;
	name: string;
	type: string;
	size: number;
}

export interface Location {
	address?: string;
	google_place_id?: string;
	coordinates?: google.maps.LatLngLiteral;
	full_response?: any;
}

export interface AddressFromUrlResult {
	place: string;
	google_place_id?: string;
	point: {
		lat: number;
		lng: number;
	};
	full_response?: any;
}

export enum AgreementStatus {
	ENABLED = 'enabled',
	DISABLED = 'disabled',
}

export interface Agreement {
	id: string;
	seller_id: string;
	name: string;
	product_label: string;
	token_validity: number;
	short_description: string;
	long_description: string;
	conditions_detail: string;
	conditions_general: string;
	conditions_payment: string;
	conditions: string;
	details: string;
	payment_conditions: string;
	max_supply: number;
	min_purchase: number;
	product_exchange: boolean;
	status: AgreementStatus;
	validity: any;
	file: string;
	created_by: string;
	created_at: Date;
	token_decimals?: number;
	unity?: string;
	label_units_in_presentation?: string;
	redeem_step?: number;
	min_time_to_ship?: number;
	min_redeem?: number;
}

export enum AgreementPriceStatus {
	ENABLED = 'enabled',
	DISABLED = 'disabled',
}

// /agreements/{id}
// /agreements/{id}/products
// /agreements/{id}/pricings?status=enabled
// /agreements/{id}/pricings/{id}/collections?status=enabled

export interface AgreementPricing {
	id: string;
	agreement_id: string;
	validity: Date;
	price: number;
	coin: string;
	status: AgreementPriceStatus;
	max_capacity: number;
	min_purchase: number;
	max_purchase: number;
}

export interface AgreementProduct {
	id: string;
	agreement_id: string;
	pim_id: string;
	name: string;
	image: string;
	equivalence: number;
	units_in_presentation: number;
	unit_type: string | null;
	main: boolean;
	created_by: string;
	created_at: Date;
}

export enum AgreementCollectionStatus {
	ENABLED = 1,
	DISABLED = 0,
	EXPIRED = 2,
}

export interface AgreementCollection {
	id: string;
	agreement_pricing_id: string;
	stock: number;
	validity: string;
	status: AgreementCollectionStatus;
	created_by: string;
	created_at: Date;
}

export enum StockSafekeepingStatus {
	MINTED = 'minted',
	BLOCKED = 'blocked',
}

export interface StockSafekeeping {
	id: string;
	collection_id: number;
	order_id: string;
	wallet_id: string;
	quantity: number;
	status: StockSafekeepingStatus;
	Estado_orden: number;
	validity: string;
	created_at: string;
	files_folder_id: string;
	logo: string;
	image_url: string;
	valid_invoice_profile?: boolean;
	agreement_id?: string;
}

export interface Order {
	company_id: string;
	costo_envio: number;
	created_at: string | null;
	created_by: string | null;
	estado_entrega: number;
	estado_orden: number;
	fecha_de_cierre: string | null;
	id_orden: string;
	monto_comision: number;
	monto_descuento: number;
	monto_final: number;
	monto_interes: number;
	monto_unit_token: number;
	order_type: number;
	safekeeping_id: string;
	seller: number;
}

export interface Company {
	id: string | number;
	business_social: string;
	cuit: string;
	id_constructora: string;
	invoice_address?: string;
	validInvoiceProfile?: boolean;
}

export interface Seller {
	iddistribuidores?: string;
	seller: string;
	logo: string;
	cuit: number;
}

export interface SellerDocument {
	type: string;
	created_at: Date;
	file_id: string;
	file_type: string;
}

export interface SellerWithDocuments {
	seller: Seller;
	documents?: SellerDocument[];
}

export interface Collaborators {
	items: Collaborator[];
}

export interface Collaborator {
	user_id: string;
	user_name: string;
	user_email: string;
	area?: string;
	role: CollaboratorRole;
	status: CollaboratorStatus;
}

export enum CollaboratorStatus {
	DELETED = 'deleted',
	CONFIRMED = 'confirmed',
	PENDING = 'pending',
}

export enum CollaboratorRole {
	USER = 'user',
	ADMIN = 'admin',
	INTERNAL_ADMIN = 'internal_admin',
}

export type InviteCollaboratorRequest = {
	id_external: Pick<User, 'id_constructora'>;
	type_external: 'constructor';
	cuit: Pick<User, 'cuit_external'>;
	business_name_id: Pick<User, 'name'>;
	user_email: string;
};

export type RedeemTokenViewDataProduct = {
	id?: string;
	equivalence: string;
	name: string;
	quantity: number;
	image: string;
};

export type RedeemTokenViewDataInfo = {
	minTimeToShip?: number;
	unity: string;
	minPurchase: number;
	redeem_step?: number;
	token_decimals?: number;
};

export type RedeemTokenViewData = {
	products: RedeemTokenViewDataProduct[];
	address: any[];
	type?: string;
	files?: any;
	addresses: { key: string; label: string }[];
} & RedeemTokenViewDataInfo;

export interface ConstructionContact {
	id: string;
	addressId: string;
	name: string;
	phone: string;
}

export interface ConstructionAddressResponse {
	id: string;
	organization_id: string;
	address_alias: string;
	address: string;
	city: string;
	province: string;
	country: string;
	place_url: string;
	lat: number;
	long: number;
	google_place_id: string;
	default: boolean;
	reception: string;
	stockpilling: boolean;
	receive_rain_delivery?: boolean;
	download_side?: 'left' | 'right' | 'indifferent';
	comments?: string;
	created_by?: any;
	created_at?: any;
	deleted_at?: any;
	contacts: AddressContactResponse[];
}

export interface AddressContactResponse {
	id: string;
	address_id: string;
	contact: string;
	phone: string;
	created_by: null | any;
	created_at: null | any;
	deleted_at: null | any;
}

export interface ConstructionAddress {
	id?: string;
	organizationId?: string;
	alias: string;
	address: string;
	city: string;
	province: string;
	country: string;
	isDefault?: boolean;
	placeUrl: string;
	latitude: number;
	longitude: number;
	googlePlaceId: string;
	availability: string;
	gathering: boolean;
	receiveRainDelivery?: boolean;
	downloadSide?: 'left' | 'right' | 'indifferent';
	comments?: string;
	contacts: ConstructionContact[];
	createdBy?: string;
	createdAt?: string;
	deletedAt?: string;
}

export interface BusinessName {
	validInvoiceProfile?: boolean;
	business_social: string | null;
	created_at?: string | null;
	created_by?: string | null;
	cuit: number | null;
	default_business_name?: boolean | null;
	deleted_at?: string | null;
	id?: string | null;
	id_constructora: string;
	iibb_status?: string | null;
	invoice_address?: string | null;
	perceptions?: string | null;
	tax_status?: string | null;
	main?: boolean | null;
	files?: any;
	removedFilesIds?: string[] | null;
	inUse?: boolean;
}

export const DocumentationTypes = {
	MI_PYME_CERT: 1,
	CUIT_CERT: 2,
	IVA_EXCL_CERT: 3,
	IIBB_LOCAL_CERT: 4,
	IIBB_MULTILATERAL_01_CERT: 5,
	IIBB_MULTILATERAL_05_CERT: 6,
	IIBB_EXCL_CERT: 7,
	IVA_PERC_CERT: 8,
	IIBB_PERC_CERT: 9,
	IIGG_PERC_CERT: 10,
	SUSS_PERC_CERT: 11,
	BALANCE: 12,
	BALANCE_POST_SALES: 13,
	COMMERCIAL_REF: 14,
	MAIN_SUPPLIERS: 15,
	MAIN_CUSTOMERS: 16,
	PROJ_CASH_FLOW_OVER_12_MONTHS: 17,
	FINANCIAL_DEBT: 18,
};

export interface BaseFile {
	id?: string;
	path?: string;
	gcs_file_path?: string;
	name?: string;
	directDownload?: Boolean | false;
}

export interface BusinessFile extends BaseFile {
	bussiness_name_id?: string;
	type?: string;
	created_by?: string;
	created_at?: string;
	updated_at?: string | null;
}
export interface DollarValueInPesos {
	compra: number;
	venta: number;
	casa: string;
	nombre: string;
	moneda: string;
	fechaActualizacion: string;
}

export interface BusinessDetails {
	id?: string | null;
	bussiness_name_id: string | null;
	commercial_reference: string | null;
	supplier: string | null;
	customer: string | null;
	created_by?: string;
	created_at?: string;
	updated_at?: string | null;
	deleted_at?: string | null;
	files?: any;
	removedFilesIds?: string[] | null;
}

export enum Features {
	EMPTY_STATE_NEW_QUOTATION = 1,
	EMPTY_STATE_QUOTATIONS = 2,
	EMPTY_STATE_STOCKPILE = 3,
	EMPTY_STATE_PROJECTS = 4,
	EMPTY_STATE_QUOTATION_COMMENTS = 5,
}

export enum FeatureNames {
	NEW_QUOTATION = 'EMPTY_STATE_NEW_QUOTATION',
	QUOTATIONS = 'EMPTY_STATE_QUOTATIONS',
	STOCKPILE = 'EMPTY_STATE_STOCKPILE',
	PROJECTS = 'EMPTY_STATE_PROJECTS',
	QUOTATION_COMMENTS = 'EMPTY_STATE_QUOTATION_COMMENTS',
}

export enum Areas {
	TECHNICAL = 'technical_office_and_tenders',
	FINANCES = 'administration_and_finance',
	CONSTRUCTIONS = 'works',
	PURCHASES = 'purchase',
}

export enum AgreementConditionTypes {
	CONFIG = '0',
	SHIPPING = '1',
	TOKENS = '2',
	PRICE = '3',
	FINANCING = '4',
	EQUIVALENCE = '5',
	WITHDRAWAL = '6',
}

export interface UserFeature {
	id?: string;
	user_id: number;
	feature_id: number;
	active: boolean;
	date?: string;
}

export interface StackedData {
	objectTitle?: ReactNode;
	objectDesc?: ReactNode;
	title: string;
	description: string | any;
	extraFullRow?: ReactNode;
	extraFullRowTooltip?: string;
}
